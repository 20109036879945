.navIcon {
    display: block;
    font-family: 'pea_soupregular', Arial, sans-serif;
    font-size: 1.5rem;
    color: #fa6325;
    text-align: center;
    text-decoration: none;
    -webkit-transition: color 0.3s;
    -moz-transition: color 0.3s;
    transition: color 0.3s;
    margin-left: 16px;
    margin-right: 16px;
    margin-bottom: 8px;
}

.navIcon img {
    color: #ffffff;
    display: block;
    width: 2rem;
    height: 2rem;
    padding: 0.3rem;
    margin: 0 auto 0.2rem auto;

    background-color: #fa6325;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 60%;

    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.4);
    border: 3px solid transparent;
    border-radius: 50%;

    -webkit-transition:
        box-shadow,
        border 0.3s;
    -moz-transition:
        box-shadow,
        border 0.3s;
    transition:
        box-shadow,
        border 0.3s;
}

.navIcon:hover img {
    border: 3px solid white;
    box-shadow: 0 3px 16px rgba(0, 0, 0, 0.4);
    color: white;
}

.navIcon:hover span {
    color: white;
}

@media (max-width: 475px) {
    .navIcon {
        color: white
    }
}
