/*******************Font**************************/

@font-face {
  font-family: 'pea_soupregular';
  src:
    url('fonts/peasoup-regular-webfont.woff2') format('woff2'),
    url('fonts/peasoup-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

/**************************************************/
body {
  font-family: 'Palatino', 'Times New Roman', sans-serif;
  font-weight: 100;
}

.header {
  width: 100%;

  padding-bottom: 10rem;

  text-align: center;
  color: #ffffff;

  background: rgb(0, 121, 166);
  background: linear-gradient(171deg,
      rgba(0, 121, 166, 1) 0%,
      rgba(164, 131, 176, 1) 80%,
      rgba(179, 118, 149, 1) 100%);

  background-size: cover;

  clip-path: polygon(100% 0%,
      0% 0%,
      0% 68.55%,
      1% 69.35%,
      2% 70.2%,
      3% 71.1%,
      4% 72.05%,
      5% 73.05%,
      6% 74.1%,
      7% 75.15%,
      8% 76.3%,
      9% 77.4%,
      10% 78.55%,
      11% 79.7%,
      12% 80.85%,
      13% 82%,
      14% 83.15%,
      15% 84.25%,
      16% 85.35%,
      17% 86.4%,
      18% 87.45%,
      19% 88.4%,
      20% 89.35%,
      21% 90.2%,
      22% 91.05%,
      23% 91.8%,
      24% 92.45%,
      25% 93.05%,
      26% 93.6%,
      27% 94.05%,
      28% 94.4%,
      29% 94.7%,
      30% 94.85%,
      31% 95%,
      32% 95%,
      33% 94.9%,
      34% 94.75%,
      35% 94.5%,
      36% 94.2%,
      37% 93.75%,
      38% 93.25%,
      39% 92.7%,
      40% 92.05%,
      41% 91.3%,
      42% 90.5%,
      43% 89.65%,
      44% 88.75%,
      45% 87.8%,
      46% 86.8%,
      47% 85.75%,
      48% 84.65%,
      49% 83.55%,
      50% 82.4%,
      51% 81.65%,
      52% 80.5%,
      53% 79.35%,
      54% 78.2%,
      55% 77.05%,
      56% 75.9%,
      57% 74.8%,
      58% 73.75%,
      59% 72.75%,
      60% 71.75%,
      61% 70.8%,
      62% 69.9%,
      63% 69.1%,
      64% 68.35%,
      65% 67.65%,
      66% 67.05%,
      67% 66.5%,
      68% 66.05%,
      69% 65.65%,
      70% 65.35%,
      71% 65.15%,
      72% 65.05%,
      73% 65%,
      74% 65.05%,
      75% 65.2%,
      76% 65.45%,
      77% 65.75%,
      78% 66.15%,
      79% 66.65%,
      80% 67.2%,
      81% 67.85%,
      82% 68.55%,
      83% 69.35%,
      84% 70.2%,
      85% 71.1%,
      86% 72.05%,
      87% 73.05%,
      88% 74.1%,
      89% 75.15%,
      90% 76.3%,
      91% 77.4%,
      92% 78.55%,
      93% 79.7%,
      94% 80.85%,
      95% 82%,
      96% 83.15%,
      97% 84.25%,
      98% 85.35%,
      99% 86.4%,
      100% 87.45%);
}

.title {
  margin: 0;
  font: inherit;

  font-family: 'pea_soupregular', cursive;
  font-size: 4rem;
  padding-bottom: 1rem;
  text-shadow: 0 3px 6px rgba(0, 0, 0, 0.38);
}

.description {
  margin: 0;
  font: inherit;
  font-size: 1.2rem;
}

.decorationLeft {
  position: absolute;
  margin-top: -10rem;
}

.decorationLeftImage {
  margin-right: -8rem;
}

.decorationRight {
  position: absolute;
  margin-top: -10rem;
  right: 0;
}

.decorationRightTop {
  position: absolute;
  margin-top: -10rem;
  right: 3rem;
  top: 18rem;
}

.decorationBalloon2 {
  width: 9rem;
  height: 7rem;
  background: url(pictures/balloon.svg) no-repeat center;
  background-size: contain;
}

.activities {
  width: 100%;
  max-width: 1024px;
  padding: 3rem 0;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #ffffff;
}

.navigation {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: 8px;
  padding-right: 24px;
}

@media (max-width: 800px) {
  .description {
    font-size: 1rem;
    margin: 1rem;
  }

  .activitiesMobile {
    flex-direction: column;
  }
}

@media (min-width: 920px) {
  .headerDesktop {
    text-align: left;
  }

  .headerImageDesktop {
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    font-size: 100%;
    vertical-align: baseline;

    margin-top: 3rem;
    margin-bottom: -10.5rem;
    position: relative;
    z-index: 2;
  }

  .titleDesktop {
    margin-left: 25rem;
  }

  .description {
    font-size: 1.2rem;
    margin-left: 25rem;
  }
}
