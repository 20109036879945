.blogPostsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Arial', sans-serif;
  color: #333;
  margin-bottom: 128px;
}

.blogPostsContainer h1 {
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.blogPostPreview {
  max-width: 845px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 32px;
  margin-left: 16px;
  margin-right: 16px;
  text-decoration: none;
  color: inherit;
}

.blogPostPreviewDivider {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 32px;
  margin-left: 16px;
  margin-right: 16px;
  width: 60%;
  height: 1px;
  background-color: #bbb;
  margin-bottom: 32px;
}

.blogPostPreviewTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 830px;
  text-align: left;
}

.blogPostPreviewTitle h2 {
  font-size: 24px;
  line-height: 1.2;
  color: #2c3e50;
  margin-bottom: 16px;
}

.blogPostPreviewTitle h3 {
  font-size: 16px;
  color: #657a7b;
  margin-bottom: 8px;
}

.blogPostPreviewTitle figure {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  margin: 0px;
  padding: 0px;
}

.blogPostPreviewTitle figure img {
  width: 100%;
  max-width: 400px;
}

.blogPostPreviewDescription {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: flex-start;
  padding-right: 8px;
}

.blogPostPreviewSubtitle h2 {
  font-size: 16px;
  color: #657a7b;
  margin-bottom: 8px;
}

@media (max-width: 840px) {
  .blogPostPreviewDivider {
    width: 75%;
  }
}
