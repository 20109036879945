/*********CSS Reset********/
html {
  /* https://developer.apple.com/library/archive/documentation/AppleApplications/Reference/SafariWebContent/AdjustingtheTextSize/AdjustingtheTextSize.html#//apple_ref/doc/uid/TP40006510-SW16 */
  -webkit-text-size-adjust: 100%;
  /* Prevent font scaling in landscape while allowing user zoom */
}

#root {
  white-space: pre-line;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1.6rem;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/**************************************************/

footer {
  width: 100%;
  padding: 0.3rem;
  box-sizing: border-box;

  position: fixed;
  bottom: 0;
  z-index: 2;

  background: rgb(0, 121, 166);
  color: white;
}

footer a {
  color: white;
}

footer p {
  margin: 0;
}

/************************************ Feedback ***************************/

.feedback {
  display: inline-block;
  float: right;

  cursor: pointer;
  text-decoration: underline;
}

.feedback-modal {
  position: fixed;
  height: 200px;
  width: 300px;

  right: -300px;
  bottom: -110px;

  border-radius: 50% 50% 1% 50%;
  border: 6px solid white;
  background-color: #3a9b92;

  box-shadow: 0 3px 16px rgba(0, 0, 0, 0.4);

  transition:
    right,
    bottom 0.3s;
}

.feedback-modal form {
  margin: 10% 10% 0 10%;

  text-align: center;
  color: #ffffff;
}

.feedback-modal form label {
  display: block;
}

.feedback-text textarea {
  width: 100%;

  border: 3px solid white;
  border-radius: 1rem;
  background-color: rgba(0, 0, 0, 0.4);

  color: #ffffff;

  overflow: scroll;
}

.feedback-send input[type='button'] {
  border: 3px solid white;
  border-radius: 1rem;
  background-color: white;

  font-family: 'pea_soupregular', sans-serif;
  font-size: 1.2rem;

  cursor: pointer;

  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.4);

  -webkit-transition: box-shadow 0.3s;
  -moz-transition: box-shadow 0.3s;
  transition: box-shadow 0.3s;
}

.feedback-send input[type='button']:hover {
  box-shadow: 0 3px 16px rgba(0, 0, 0, 0.4);
}

.feedback-send input[type='button']:active {
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.4);
}

#close-modal {
  position: absolute;
  right: 2rem;

  color: #3a9b92;
  text-shadow: 0 3px 16px rgba(0, 0, 0, 0.4);
  cursor: pointer;
}

/************************************ Imprint ***************************/

h3 {
  font-size: 1.1rem;
  font-weight: bold;
}

@media (max-width: 840px) {
  .xs-hidden {
    display: none;
  }
}
