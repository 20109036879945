.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Arial', sans-serif;
  color: #333;
  margin: auto;
  padding-left: 16px;
  padding-right: 16px;
}

.container h1 {
  font-size: 2.5rem;
  line-height: 1.2;
  color: #2c3e50;
  margin-bottom: 16px;
}

.container p {
  line-height: 1.8;
  font-size: 1.1rem;
  text-align: left;
}
