.blog-post-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Arial', sans-serif;
  color: #333;
  margin: auto;
}

.blog-post {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 32px;
  max-width: 66ch;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 128px;
}

.blog-post-header {
  width: 100%;
  max-width: 830px;
  text-align: center;
  margin-bottom: 32px;
}

.blog-post-header h1 {
  font-size: 32px;
  line-height: 1.2;
  color: #2c3e50;
  margin-bottom: 16px;
}

.blog-post-header h2 {
  font-size: 1.5rem;
  color: #657a7b;
  margin-bottom: 8px;
}

.blog-post-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.blog-post-body figure {
  width: 100%;
  margin: 0;
  padding: 0;
}

.blog-post-body figure img {
  width: 100%;
  max-width: 830px;
  height: auto;
  display: block;
  margin: auto;
}

.blog-post-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  max-width: 800px;
  margin-top: 32px;
}

.blog-post-content h2 {
  color: #34495e;
  margin-top: 24px;
  margin-bottom: 12px;
}

.blog-post-content h3 {
  color: #34495e;
  margin-top: 20px;
  margin-bottom: 8px;
}

.blog-post-content p,
.blog-post-content ul,
.blog-post-content ol {
  line-height: 1.8;
  font-size: 1.1rem;
  text-align: left;
}

.blog-post-content ul,
.blog-post-content ol {
  padding-left: 32px;
  margin: 0 0 0 0;
}

.blog-post-content ul {
  list-style-type: disc;
}

.blog-post-content ol {
  list-style-type: decimal;
}

.blog-post-content figure img {
  max-width: 300px;
}

.blog-post-content table {
  max-width: calc(100vw - 32px);
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
}

.blog-post-content th {
  background-color: #d9d9d9;
}

.blog-post-content th,
.blog-post-content td {
  border: 1px solid #657a7b;
  text-align: left;
  padding: 8px;
}
