.activityCard {
  width: 25%;
  padding: 1rem;
  border: 6px solid white;
  border-radius: 31px 31px 70px 31px;
  background-size: 100% 100%;
  box-shadow: 0 3px 16px rgba(0, 0, 0, 0.4);
  color: white;
  transition: transform 0.3s;
  margin-bottom: 5rem;
  cursor: pointer;
}

.activityCard:hover {
  transform: rotate(10deg);
}

.activity-decoration {
  margin: -3rem;
  width: 5rem;
  height: 5rem;
  float: right;
}

.activity-description {
  font-family: 'pea_soupregular', cursive;
  font-size: 1.5rem;
  font-weight: lighter;
  padding: 0.8rem 0.5rem 0 0.5rem;
}

.activity-description p {
  margin: 0;
}

.activity-header {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}

.activity-header span {
  display: inline-block;
  padding: 0.4rem 0.6rem;
  background-color: rgba(57, 57, 57, 0.57);
  font: inherit;
  font-size: 100%;
}

.activity-picture {
  width: 200px;
  height: 150px;
  box-sizing: border-box;
  padding: 1rem 1rem 2rem 1rem;
  margin-top: 2rem;
  margin-bottom: -5rem;
  background-color: white;
  box-shadow: 4px 8px 8px hsl(0deg 0% 0% / 0.38);
  transform: rotate(1deg);
}

.activity-picture::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  margin-right: -1rem;
  width: 3rem;
  height: 1rem;
  background-color: rgba(255, 167, 0, 0.75);
  transform: rotate(34deg);
}

.activity-picture::before {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  margin-left: -1rem;
  width: 3rem;
  height: 1rem;
  background-color: rgba(255, 167, 0, 0.75);
  transform: rotate(-46deg);
}

.activity-picture-frame {
  width: calc(200px - 2rem);
  height: calc(150px - 3rem);
}

.activity-picture-frame img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

#activity1 {
  background-color: #586bdf;
}

#activity1 .activity-decoration {
  background: url(../pictures/pencil.svg) no-repeat;
  background-size: contain;
  width: 80px;
  height: 80px;
}

#activity1 .activity-picture {
  transform: rotate(5deg);
}

#activity2 {
  margin-top: -8rem;
  border-radius: 31px 70px 31px 31px;
  background-color: #3a9b92;
}

#activity2 .activity-decoration {
  background: url(../pictures/pencil.svg) no-repeat;
  background-size: contain;
  transform: rotate(10deg);
}

#activity2 .activity-decoration img {
  transform: scale(0.55) translateY(-2rem) translateX(-1rem);
  width: 140%;
  height: 140%;
}

#activity3 {
  border-radius: 31px 31px 31px 70px;
  background-color: #bd6cf4;
}

#activity3 .activity-decoration {
  background: url(../pictures/pencil-1.svg) no-repeat;
  background-size: contain;
}

#activity3 .activity-decoration img {
  transform: rotate(15deg) scale(0.7) translateY(-3rem) translateX(-4rem);
  width: 185%;
  height: 185%;
}

#activity3 .activity-decoration img:last-child {
  transform: scale(0.7) translateY(-18rem) translateX(-4rem);
  width: 190%;
  height: 190%;
}

#activity3 .activity-picture {
  transform: rotate(-8deg);
}

@media (max-width: 800px) {
  .activityCardMobile {
    width: calc(100% - 3rem);
    margin: 1rem;
    margin-bottom: 5rem;
    box-sizing: border-box;
  }

  .activityCardMobile:hover {
    transform: none;
  }

  #activity2 {
    margin-top: 0;
  }
}
