/* PrivacyPolicy.module.css */

/* Container for the main content */
.content {
  max-width: 900px;
  margin: 1rem auto;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 128px;
  font-family: 'Arial', sans-serif;
}

/* Styles for the main title (h1 elements) */
.title {
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  margin-top: 2rem;
  margin-bottom: 1rem;
}

/* Styles for subtitles or centered text */
.subtitle {
  padding-top: 0pt;
  padding-bottom: 6pt;
  line-height: 1.15;
  text-align: center;
}

/* Styles for date lines or small centered text */
.dateLine {
  padding-top: 12pt;
  padding-bottom: 6pt;
  line-height: 1.15;
  text-align: center;
}

/* Styles for section titles (h2 elements) */
.sectionTitle {
  font-size: 24px;
  font-weight: bold;
  margin-top: 2rem;
  margin-bottom: 1rem;
}

/* Styles for regular paragraphs */
.paragraph {
  line-height: 1.5;
  text-align: justify;
  margin-top: 1rem;
}

.ownerInfo {
  margin-top: 1rem;
}

.addressLine {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.2;
  text-align: justify;
}

/* Styles for list items */

/* Text styles */
.textNormal {
  color: #000000;
  font-weight: 400;
  font-size: 11pt;
}

.textBold {
  font-weight: 700;
}

.textLarge {
  font-size: 14pt;
}

.textHuge {
  font-size: 20pt;
}

.textSmall {
  font-size: 10pt;
}

/* Styles for unordered lists */
.list {
  list-style-type: disc;
  margin-left: 1.5rem;
  margin-bottom: 1rem;
}

.listItem {
  margin-bottom: 0.5rem;
}

.list ul {
  list-style-type: disc;
}

.list li:before {
  white-space: nowrap;
  display: inline-block;
  min-width: 18pt;
}

@media (max-width: 475px) {
  .title {
    font-size: 26px;
    font-weight: bold;
    text-align: center;
    margin-top: 2rem;
    margin-bottom: 1rem;
  }
}

@media (max-width: 390px) {
  .title {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    margin-top: 2rem;
    margin-bottom: 1rem;
  }
}
