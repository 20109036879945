.header {
  width: 100%;

  text-align: center;
  color: #ffffff;

  background: rgb(0, 121, 166);
  background: linear-gradient(171deg,
      rgba(0, 121, 166, 1) 0%,
      rgba(164, 131, 176, 1) 80%,
      rgba(179, 118, 149, 1) 100%);

  background-size: cover;
}

.home {
  clip-path: none;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 1rem;
  text-align: start;
}

.home a {
  color: white;
  text-decoration: none;
  font-family: 'pea_soupregular', Arial, sans-serif;
  font-size: 1.5rem;
}

.home img {
  width: 3rem;
  margin-bottom: -1rem;
  transform: rotate(110deg);
}

.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 8px;
}

.otherNavigation {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-right: 24px;
}
