body {
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'pea_soupregular';
  src:
    url('fonts/peasoup-regular-webfont.woff2') format('woff2'),
    url('fonts/peasoup-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

.App {
  overflow: hidden;
}
