/* TermsOfService.module.css */

/* Container for the main content */
.content {
  max-width: 900px;
  margin: 1rem auto;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 128px;
  font-family: 'Arial', sans-serif;
}

/* Styles for the main title (h1 elements) */
.title {
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  margin-top: 2rem;
  margin-bottom: 1rem;
}

/* Styles for subtitles or centered text */
.subtitle {
  padding-top: 0pt;
  padding-bottom: 6pt;
  line-height: 1.15;
  text-align: center;
}

/* Styles for date lines or small centered text */
.dateLine {
  padding-top: 12pt;
  padding-bottom: 6pt;
  line-height: 1.15;
  text-align: center;
}

/* Styles for section titles (h2 elements) */
.sectionTitle {
  font-size: 24px;
  font-weight: bold;
  margin-top: 2rem;
  margin-bottom: 1rem;
}

/* Styles for regular paragraphs */
.paragraph {
  line-height: 1.5;
  text-align: justify;
  margin-top: 1rem;
}

/* Empty line */
.emptyLine {
  height: 11pt;
}

/* Link styles */
.link {
  color: #1155cc;
  text-decoration: underline;
}

@media (max-width: 450px) {
  .title {
    font-size: 26px;
    font-weight: bold;
    text-align: center;
    margin-top: 2rem;
    margin-bottom: 1rem;
  }
}
