.languageSwitcher {
    position: relative;
    display: inline-block;
}

.button {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px 16px;
    background-color: #f5f5f5;
    color: #333;
    border: 1px solid #ccc;
    cursor: pointer;
    border-radius: 4px;
    outline: none;
}

.button:hover {
    background-color: #eaeaea;
}

.iconContainer {
    display: flex;
    width: 24px;
    height: 24px;
}

.arrowIcon {
    width: 16px;
    height: 16px;
    transition: transform 0.2s ease-in-out;
}

.arrowIconRotated {
    transform: rotate(180deg);
}

.dropdown {
    position: absolute;
    top: 100%;
    right: 0;
    min-width: 100%;
    margin-top: 4px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    list-style: none;
    z-index: 10;
    padding: 4px 0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.dropdownItem {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px 16px;
    cursor: pointer;
}

.dropdownItem:hover {
    background-color: #eee;
}

.dropdownItemSelected {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px 16px;
    background-color: #dcdcdc;
    font-weight: 600;
    cursor: pointer;
}

.dropdownIcon {
    display: inline-flex;
    width: 24px;
    height: 24px;
}

.dropdownLabel {
    font-size: 14px;
    font-weight: 400;
    color: #2c3e50;
}
